// src/components/Dashboard/index.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import { auth } from '../../config/firebaseConfig';
import { signOut } from 'firebase/auth';
import { toast } from 'react-toastify';
import { Button, Typography } from '@mui/material';

const Dashboard: React.FC = () => {
  return <div></div>;
};

export default Dashboard;
