// src/components/Unauthorized/index.tsx
import React from 'react';
import { Button, Typography } from '@mui/material';

interface UnauthorizedProps {
  message: string;
  upgradePlan?: () => void;
}

const Unauthorized: React.FC<UnauthorizedProps> = ({
  message,
  upgradePlan,
}) => {
  return (
    <div style={{ padding: '20px', textAlign: 'center' }}>
      <Typography variant="h6">{message}</Typography>
      {upgradePlan && (
        <Button
          variant="contained"
          color="primary"
          onClick={upgradePlan}
          style={{ marginTop: '20px' }}
        >
          Upgrade Plan
        </Button>
      )}
    </div>
  );
};

export default Unauthorized;
