import { useEffect, useState, useCallback } from 'react';
import { auth, db } from '../config/firebaseConfig';
import { User, onAuthStateChanged, getIdTokenResult } from 'firebase/auth';
import {
  doc,
  getDoc,
  setDoc,
  updateDoc,
  collection,
  getDocs,
} from 'firebase/firestore';

interface MasterQuota {
  app: string;
  feature: string;
  action: string;
  plan: string;
  quota: number;
  isQuotaManaged: boolean;
}

export const useAuth = () => {
  const [user, setUser] = useState<User | null>(auth.currentUser);
  const [initializing, setInitializing] = useState(true);
  const [claims, setClaims] = useState<any>(null);

  const [plan, setPlan] = useState<string>('Free'); // User's plan
  const [masterQuotas, setMasterQuotas] = useState<MasterQuota[]>([]);
  const [usage, setUsage] = useState<any>({}); // User's consumption data

  const fetchMasterQuotas = useCallback(async () => {
    try {
      const snapshot = await getDocs(collection(db, 'masterQuotas'));
      const quotas: MasterQuota[] = [];
      snapshot.forEach((docSnap) => {
        quotas.push(docSnap.data() as MasterQuota);
      });
      setMasterQuotas(quotas);
    } catch (error) {
      console.error('Error fetching master quotas:', error);
    }
  }, []);

  const fetchUserData = useCallback(async (uid: string) => {
    try {
      const userDocRef = doc(db, 'users', uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        setPlan(userData?.plan || 'Free');
        setUsage(userData?.usage || {});
      } else {
        await setDoc(userDocRef, { plan: 'Free', usage: {} });
        setPlan('Free');
        setUsage({});
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
    }
  }, []);

  // Function to sync data every 2 minutes
  const startDataSync = useCallback(
    (uid: string) => {
      const interval = setInterval(
        async () => {
          await fetchMasterQuotas();
          await fetchUserData(uid);
        },
        2 * 60 * 1000
      ); // Every 2 minutes

      return () => clearInterval(interval);
    },
    [fetchMasterQuotas, fetchUserData]
  );

  // Reset usage on the 1st of every month
  const resetUsageIfNeeded = useCallback(async (uid: string) => {
    const now = new Date();
    if (now.getDate() === 1) {
      // Reset usage in Firestore
      try {
        const userDocRef = doc(db, 'users', uid);
        await updateDoc(userDocRef, { usage: {} });
        setUsage({});
      } catch (error) {
        console.error('Error resetting usage:', error);
      }
    }
  }, []);

  useEffect(() => {
    const unregisterAuthObserver = onAuthStateChanged(
      auth,
      async (firebaseUser) => {
        try {
          if (firebaseUser) {
            setUser(firebaseUser);
            const tokenResult = await getIdTokenResult(firebaseUser);
            setClaims(tokenResult.claims);

            // Fetch data
            await fetchMasterQuotas();
            await fetchUserData(firebaseUser.uid);
            await resetUsageIfNeeded(firebaseUser.uid);

            // Start periodic data sync
            const stopDataSync = startDataSync(firebaseUser.uid);
            return () => {
              stopDataSync();
            };
          } else {
            setUser(null);
            setClaims(null);
            setPlan('Free');
            setUsage({});
          }
        } catch (error) {
          console.error('Error in auth state change:', error);
        } finally {
          setInitializing(false);
        }
      },
      (error) => {
        console.error('Auth state change error:', error);
        setInitializing(false);
      }
    );

    return () => unregisterAuthObserver();
  }, [fetchMasterQuotas, fetchUserData, resetUsageIfNeeded, startDataSync]);

  // Function to update usage
  const updateUsage = async (
    app: string,
    feature: string,
    action: string,
    amount: number = 1
  ) => {
    if (!user) return;
    const now = new Date();
    const monthKey = `${now.getFullYear()}-${String(
      now.getMonth() + 1
    ).padStart(2, '0')}`; // e.g., '2024-10'

    const newUsage = { ...usage };
    if (!newUsage[app]) newUsage[app] = {};
    if (!newUsage[app][feature]) newUsage[app][feature] = {};
    if (!newUsage[app][feature][action]) newUsage[app][feature][action] = {};
    if (!newUsage[app][feature][action][monthKey])
      newUsage[app][feature][action][monthKey] = 0;

    newUsage[app][feature][action][monthKey] += amount;

    // Update local state
    setUsage(newUsage);

    // Update Firestore
    try {
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, { usage: newUsage });
    } catch (error) {
      console.error('Error updating usage:', error);
    }
  };

  // Function to handle plan upgrade
  const upgradePlan = async (newPlan: string) => {
    if (!user) return;
    try {
      // Update plan in Firestore
      const userDocRef = doc(db, 'users', user.uid);
      await updateDoc(userDocRef, { plan: newPlan });

      // Update plan in state
      setPlan(newPlan);
    } catch (error) {
      console.error('Error upgrading plan:', error);
    }
  };

  return {
    user,
    initializing,
    claims,
    plan,
    masterQuotas,
    usage,
    updateUsage,
    upgradePlan,
  };
};
