// src/components/Loading/index.tsx
import React from 'react';
import styles from './Loading.module.scss';
import logo from '../../assets/logo-white.png';

const Loading: React.FC = () => {
  return (
    <div className={styles.loadingContainer}>
      <img src={logo} alt="Logo" className={styles.logo} />
      <div className={styles.spinner}></div>
    </div>
  );
};

export default Loading;
