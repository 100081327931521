import React from 'react';
import { Routes, Route } from 'react-router-dom';
import Login from '../../modules/login';
import Dashboard from '../../modules/dashboard';
import MainLayout from '../layouts/MainLayout';
import LoginLayout from '../layouts/LoginLayout';
import ProtectedRoute from './ProtectedRoute';
import Unauthorized from '../../components/unauthorized';

const AppRoutes: React.FC = () => {
  return (
    <Routes>
      <Route
        path="/login"
        element={
          <LoginLayout>
            <Login />
          </LoginLayout>
        }
      />
      <Route
        path="/unauthorized"
        element={
          <LoginLayout>
            <Unauthorized message="You do not have permission to access this page." />
          </LoginLayout>
        }
      />
      <Route
        path="/logviewer/dashboard"
        element={
          <ProtectedRoute app="LogViewer" feature="Dashboard" action="Export">
            {/* Dashboard component */}
            <Dashboard />
          </ProtectedRoute>
        }
      />
      {/* Other routes */}
      <Route
        path="*"
        element={
          <ProtectedRoute>
            <MainLayout>
              <Routes>
                <Route path="/" element={<Dashboard />} />
                {/* Add other routes here */}
              </Routes>
            </MainLayout>
          </ProtectedRoute>
        }
      />
    </Routes>
  );
};

export default AppRoutes;
