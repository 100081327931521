// src/contexts/ThemeContext.tsx
import React, { createContext, useState, useContext, useEffect } from 'react';
import { useAuth } from '../hooks/useAuth';
import { db } from '../config/firebaseConfig';
import { doc, onSnapshot, setDoc, updateDoc } from 'firebase/firestore';
import Loading from '../components/loading'; // Adjust import if necessary

interface ThemeContextType {
  themeMode: 'light' | 'dark';
  toggleTheme: () => void;
}

const ThemeContext = createContext<ThemeContextType | undefined>(undefined);

export const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { user, initializing: authInitializing } = useAuth();
  const [themeMode, setThemeMode] = useState<'light' | 'dark' | null>(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (authInitializing) {
      // Wait until authentication is initialized
      return;
    }

    if (user) {
      // Fetch the themeMode from Firestore
      const userDocRef = doc(db, 'users', user.uid);
      const unsubscribe = onSnapshot(
        userDocRef,
        (docSnapshot) => {
          if (docSnapshot.exists()) {
            const data = docSnapshot.data();
            if (data && data.themeMode) {
              setThemeMode(data.themeMode);
            } else {
              setThemeMode('light'); // Default theme
            }
          } else {
            // If user document doesn't exist, set default theme
            setDoc(userDocRef, { themeMode: 'light' }).then(() => {});
            setThemeMode('light');
          }
          setLoading(false);
        },
        (error) => {
          console.error('Error fetching theme mode:', error);
          setThemeMode('light');
          setLoading(false);
        }
      );
      return () => unsubscribe();
    } else {
      // User is not logged in
      setThemeMode('light');
      setLoading(false);
    }
  }, [user, authInitializing]);

  const toggleTheme = async () => {
    if (!themeMode) return;
    const newTheme: 'light' | 'dark' = themeMode === 'light' ? 'dark' : 'light';
    setThemeMode(newTheme);

    if (user) {
      try {
        const userDocRef = doc(db, 'users', user.uid);
        await updateDoc(userDocRef, { themeMode: newTheme });
      } catch (error) {
        console.error('Error updating theme mode:', error);
        // Optionally revert the theme in state
        setThemeMode(themeMode);
      }
    } else {
      console.error('No authenticated user found.');
    }
  };

  if (loading || themeMode === null) {
    // Optionally, render a loading screen
    return <Loading />;
  }

  return (
    <ThemeContext.Provider value={{ themeMode, toggleTheme }}>
      {children}
    </ThemeContext.Provider>
  );
};

export const useThemeMode = () => {
  const context = useContext(ThemeContext);
  if (!context) {
    throw new Error('useThemeMode must be used within a ThemeProvider');
  }
  return context;
};
