import React from 'react';
import Popover, { PopoverProps } from '@mui/material/Popover';
import styles from './Submenu.module.scss';

interface SubmenuProps extends PopoverProps {
  heading: string;
  footer: React.ReactNode;
}

const Submenu: React.FC<SubmenuProps> = ({
  heading,
  children,
  footer,
  ...props
}) => {
  return (
    <Popover classes={{ root: styles.root, paper: styles.paper }} {...props}>
      <div className={styles.heading}>{heading}</div>
      <div className={styles.content}>{children}</div>
      {footer && <div className={styles.footer}>{footer}</div>}
    </Popover>
  );
};

export default Submenu;
