// src/app/routes/ProtectedRoute.tsx

import React, { useState } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';
import Loading from '../../components/loading';
import Unauthorized from '../../components/unauthorized';
import UpgradeModal from '../../components/upgrade'; // Implement this component

interface ProtectedRouteProps {
  children: React.ReactNode;
  requiredPlan?: string; // Plan required to access this route
  app?: string; // App name (used for quota check)
  feature?: string;
  action?: string;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({
  children,
  requiredPlan,
  app,
  feature,
  action,
}) => {
  const { user, initializing, plan, masterQuotas, usage } = useAuth();
  const location = useLocation();
  const [showUpgrade, setShowUpgrade] = useState(false);

  if (initializing) {
    return <Loading />;
  }

  if (!user) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }

  // Check plan
  if (requiredPlan && plan !== requiredPlan) {
    return (
      <Unauthorized message="You need to upgrade your plan to access this feature." />
    );
  }

  // Check quota if applicable
  if (app && feature && action) {
    const now = new Date();
    const monthKey = `${now.getFullYear()}-${String(now.getMonth() + 1).padStart(2, '0')}`;
    const quotaItem = masterQuotas.find(
      (item) =>
        item.app === app &&
        item.feature === feature &&
        item.action === action &&
        item.plan === plan
    );

    if (quotaItem && quotaItem.isQuotaManaged) {
      const userUsage = usage[app]?.[feature]?.[action]?.[monthKey] || 0;
      if (userUsage >= quotaItem.quota) {
        // Show upgrade modal
        setShowUpgrade(true);
        return <UpgradeModal onClose={() => setShowUpgrade(false)} />;
      }
    }
  }

  return <>{children}</>;
};

export default ProtectedRoute;
