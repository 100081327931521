// src/components/upgrade/index.tsx
import React from 'react';
import {
  Box,
  Typography,
  Button,
  Card,
  CardContent,
  Grid,
} from '@mui/material';
import { useAuth } from '../../hooks/useAuth';
import { useTranslation } from 'react-i18next';

interface UpgradeModalProps {
  onClose: () => void;
}

const UpgradeModal: React.FC<UpgradeModalProps> = ({ onClose }) => {
  const { plan, upgradePlan } = useAuth();
  const { t } = useTranslation();

  const plans = [
    {
      name: 'Free',
      key: 'free',
      features: [t('upgrade.features.feature1')],
    },
    {
      name: 'Pro',
      key: 'pro',
      features: [
        t('upgrade.features.feature1'),
        t('upgrade.features.feature2'),
        t('upgrade.features.feature3'),
      ],
      recommended: true,
    },
    {
      name: 'Ultimate',
      key: 'ultimate',
      features: [
        t('upgrade.features.feature1'),
        t('upgrade.features.feature2'),
        t('upgrade.features.feature3'),
        t('upgrade.features.feature4'),
        t('upgrade.features.feature5'),
      ],
    },
  ];

  const handleSelectPlan = async (newPlan: string) => {
    await upgradePlan(newPlan);
    onClose();
  };

  return (
    <Box
      sx={{
        width: '400px',
        backgroundColor: (theme) => theme.palette.background.paper,
        color: (theme) => theme.palette.text.primary,
        borderRadius: 2,
        boxShadow: 5,
        p: 2,
      }}
    >
      <Typography variant="h5" fontWeight="bold" mb={2}>
        {t('upgrade.title')}
      </Typography>
      <Typography variant="body2" mb={3}>
        {t('upgrade.description', { plan })}
      </Typography>
      <Grid container spacing={2}>
        {plans.map((p) => (
          <Grid item xs={12} sm={4} key={p.key}>
            <Card
              sx={{
                border: p.recommended ? '2px solid #1ABC9C' : '1px solid',
                borderColor: (theme) =>
                  p.recommended ? '#1ABC9C' : theme.palette.divider,
                borderRadius: 2,
              }}
            >
              <CardContent>
                <Typography variant="h6" fontWeight="bold">
                  {t(`upgrade.plans.${p.key}`)}
                </Typography>
                {p.recommended && (
                  <Typography variant="caption" color="primary">
                    {t('upgrade.recommended')}
                  </Typography>
                )}
                <Box mt={2}>
                  {p.features.map((f, i) => (
                    <Typography variant="body2" key={i} mb={1}>
                      - {f}
                    </Typography>
                  ))}
                </Box>
                <Button
                  variant="contained"
                  color="primary"
                  sx={{ mt: 2 }}
                  onClick={() => handleSelectPlan(p.name)}
                >
                  {t('upgrade.selectPlan')}
                </Button>
              </CardContent>
            </Card>
          </Grid>
        ))}
      </Grid>
      <Box textAlign="right" mt={3}>
        <Button variant="text" color="inherit" onClick={onClose}>
          {t('upgrade.close')}
        </Button>
      </Box>
    </Box>
  );
};

export default UpgradeModal;
