import React, { Component, ErrorInfo } from 'react';
import StackTrace from 'stacktrace-js';
import { toast } from 'react-toastify';

interface ErrorBoundaryProps {
  children: React.ReactNode;
}

interface ErrorBoundaryState {
  hasError: boolean;
  errorMessage: string;
  errorStack: string;
}

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);
    this.state = { hasError: false, errorMessage: '', errorStack: '' };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state to show that an error occurred
    return { hasError: true, errorMessage: error.message };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    // You can log the error to an external service here (optional)
    console.error(error, errorInfo);

    StackTrace.fromError(error).then((stackframes) => {
      const errorStack = stackframes.map((sf) => sf.toString()).join('\n');
      this.setState({ errorStack });

      // Display error message to the user using react-toastify
      toast.error(`An error occurred: ${error.message}`);
    });
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI here
      return (
        <div>
          <h1>Something went wrong.</h1>
          <p>Error: {this.state.errorMessage}</p>
          {/* Optionally display the stack trace */}
          {/* <details>
            <summary>Error Stack Trace</summary>
            <pre>{this.state.errorStack}</pre>
          </details> */}
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
