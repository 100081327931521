// src/App.tsx
import React from 'react';
import { ThemeProvider } from './contexts/ThemeContext';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import lightTheme from './themes/lightTheme';
import darkTheme from './themes/darkTheme';
import { useThemeMode } from './contexts/ThemeContext';
import CssBaseline from '@mui/material/CssBaseline';
import { BrowserRouter as Router } from 'react-router-dom';
import AppRoutes from './app/routes/AppRoutes';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './styles/toastStyles.css';
import ErrorBoundary from './components/error/boundary';
import { AuthProvider } from './contexts/AuthContext';

const AppContent: React.FC = () => {
  const { themeMode } = useThemeMode();

  return (
    <MuiThemeProvider theme={themeMode === 'light' ? lightTheme : darkTheme}>
      <CssBaseline />
      <Router>
        <AppRoutes />
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme={themeMode} // Use themeMode here
          // Optional: Customize toast styles
          // toastStyle={
          //   themeMode === 'light'
          //     ? { backgroundColor: '#fff', color: '#000' }
          //     : { backgroundColor: '#424242', color: '#fff' }
          // }
        />
      </Router>
    </MuiThemeProvider>
  );
};

const App: React.FC = () => {
  return (
    <ThemeProvider>
      <AuthProvider>
        <ErrorBoundary>
          <AppContent />
        </ErrorBoundary>
      </AuthProvider>
    </ThemeProvider>
  );
};

export default App;
