import { auth, db } from '../config/firebaseConfig';
import { doc, updateDoc } from 'firebase/firestore';

interface UserSettings {
  language?: string;
  theme?: string;
}

export const updateUserSettings = async (
  settings: UserSettings
): Promise<void> => {
  const user = auth.currentUser;
  if (!user) return;

  const userRef = doc(db, 'users', user.uid);
  // Spread the settings into a plain object to ensure correct typing
  await updateDoc(userRef, { ...settings });
};
