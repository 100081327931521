// src/themes/darkTheme.ts
import { createTheme } from '@mui/material/styles';

const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    primary: {
      main: '#2C3E50',
    },
    secondary: {
      main: '#1ABC9C',
    },
  },
});

export default darkTheme;
