// src/components/layouts/MainLayout.tsx
import React, { useState } from 'react';
import Header from '../../components/header';
import Sidebar from '../../components/sidebar';
import styles from './MainLayout.module.scss';

interface MainLayoutProps {
  children: React.ReactNode;
}

const MainLayout: React.FC<MainLayoutProps> = ({ children }) => {
  const [collapsed, setCollapsed] = useState(true);

  const handleCollapseToggle = () => {
    setCollapsed(!collapsed);
  };

  return (
    <div
      className={`${styles.mainLayout} ${
        collapsed ? styles.sidebarCollapsed : ''
      }`}
    >
      <Header />
      <div className={styles.body}>
        <Sidebar
          collapsed={collapsed}
          onCollapseToggle={handleCollapseToggle}
        />
        <div className={styles.content}>{children}</div>
      </div>
    </div>
  );
};

export default MainLayout;
