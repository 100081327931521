// src/data/menuItems.tsx
import { Note as NoteIcon, Language } from '@mui/icons-material';
import { ReactNode } from 'react';

interface SubMenuItem {
  name: string;
  planRequired: string[];
  icon: ReactNode;
  route: string;
}

interface MenuItem {
  name: string;
  planRequired: string[];
  icon: ReactNode;
  route: string;
  subMenu?: SubMenuItem[];
}

const menuItems: MenuItem[] = [
  {
    name: 'Domain',
    planRequired: ['Free', 'Pro', 'Ultimate'],
    icon: <Language />,
    route: '/domain/',
    subMenu: [
      {
        name: 'Tracer',
        planRequired: ['Free', 'Pro', 'Ultimate'],
        icon: <NoteIcon />,
        route: '/domain/tracer',
      },
    ],
  },
];

export default menuItems;
