// src/components/common/arrow-paper/index.tsx
import { styled, Paper } from '@mui/material';

export const ArrowPaper = styled(Paper)(({ theme }) => ({
  position: 'relative',
  backgroundColor: theme.palette.background.paper,
  color: theme.palette.text.primary,
  borderRadius: theme.shape.borderRadius,
  boxShadow: theme.shadows[5],
  padding: theme.spacing(2),
  '&::before': {
    content: '""',
    position: 'absolute',
    top: '50%',
    transform: 'translateY(-50%)',
    left: '-12px',
    width: 0,
    height: 0,
    borderTop: '9px solid transparent',
    borderBottom: '9px solid transparent',
    borderRight: `12px solid ${theme.palette.background.paper}`,
    boxShadow: '1px 0px 2px rgba(0,0,0,0.2)',
  },
}));
