// src/components/layouts/LoginLayout.tsx
import React from 'react';
import styles from './LoginLayout.module.scss';

interface LoginLayoutProps {
  children: React.ReactNode;
}

const LoginLayout: React.FC<LoginLayoutProps> = ({ children }) => {
  return (
    <div className={styles.loginLayout}>
      {children}
      <footer className={styles.footer}>
        &copy; {new Date().getFullYear()} CyberZero AI
      </footer>
    </div>
  );
};

export default LoginLayout;
