import React, { useEffect, useState } from 'react';
import { auth } from '../../config/firebaseConfig';
import styles from './Login.module.scss';
import logo from '../../assets/logo-white.png';
import Button from '@mui/material/Button';
import { Divider } from '@mui/material';
import Typography from '@mui/material/Typography';
import Loading from '../../components/loading';
import { toast } from 'react-toastify';
import { useNavigate } from 'react-router-dom';
import { GoogleAuthProvider, signInWithPopup } from 'firebase/auth';

const Login: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate('/');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  const handleGoogleSignIn = async () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();
    try {
      await signInWithPopup(auth, provider);
      toast.success('Signed in successfully');
      navigate('/');
    } catch (error) {
      const typedError = error as { code?: string };
      if (typedError.code === 'auth/admin-restricted-operation') {
        toast.error(
          'No user found. Contact info@cyberzero.ai for account creation'
        );
      } else if (typedError.code === 'auth/popup-closed-by-user') {
        toast.error('Sign-in popup was closed. Please try again.');
      } else {
        toast.error('Failed to sign in. Please try again.');
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      {loading ? (
        <Loading />
      ) : (
        <div className={styles.loginContainer}>
          <img src={logo} alt="Logo" className={styles.logo} />
          <div className={styles.formContainer}>
            <Typography variant="h6">Login</Typography>
            <Divider className={styles.divider} />
            <Button
              variant="contained"
              color="primary"
              onClick={handleGoogleSignIn}
            >
              Sign in with Google
            </Button>
          </div>
        </div>
      )}
    </>
  );
};

export default Login;
