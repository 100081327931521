import { auth } from '../config/firebaseConfig';
import {
  GoogleAuthProvider,
  onAuthStateChanged,
  signInWithPopup,
  signOut,
  User,
} from 'firebase/auth';

/**
 * Watches authentication state changes and triggers the provided callback.
 * @param callback Function to call with the current user on state change.
 * @returns Unsubscribe function
 */
export const watchAuthState = (callback: (user: User | null) => void) => {
  return onAuthStateChanged(auth, callback);
};

/**
 * Signs in the user with Google provider.
 */
export const signInWithGoogle = async (): Promise<void> => {
  const provider = new GoogleAuthProvider();
  await signInWithPopup(auth, provider);
};

/**
 * Signs out the current user.
 */
export const signOutUser = async (): Promise<void> => {
  await signOut(auth);
};

// Ensure this file is a module
export {};
