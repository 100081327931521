// src/components/header/index.tsx
import React from 'react';
import { useNavigate } from 'react-router-dom';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import HomeIcon from '@mui/icons-material/Home';
import styles from './Header.module.scss';
import logo from '../../assets/logo.png';

const Header: React.FC = () => {
  const navigate = useNavigate();
  const handleHomeClick = () => {
    navigate('/');
  };

  return (
    <AppBar
      position="fixed"
      className={styles.header}
      onClick={handleHomeClick}
    >
      <Toolbar>
        <IconButton edge="start" color="inherit" aria-label="home">
          <HomeIcon />
        </IconButton>
        <div className={styles.spacer} />
        <img src={logo} alt="Logo" className={styles.logo} />
      </Toolbar>
    </AppBar>
  );
};

export default Header;
