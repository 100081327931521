// src/components/common/tooltip/index.tsx
import {
  styled,
  Tooltip as DefaultTooltip,
  tooltipClasses,
} from '@mui/material';
import styles from './Tooltip.module.scss';

export const Tooltip = styled(({ className, ...props }: any) => (
  <DefaultTooltip
    {...props}
    classes={{ popper: `${className} ${styles.tooltip}` }}
    arrow
    placement="right"
  />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: theme.palette.background.paper,
    color: theme.palette.text.primary,
    boxShadow: theme.shadows[3],
    fontSize: '0.875rem',
    borderRadius: theme.shape.borderRadius,
    padding: theme.spacing(1, 2),
    border: `1px solid ${theme.palette.divider}`, // Add a border for contrast
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: theme.palette.background.paper,
  },
}));
